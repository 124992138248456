<template>
    <layout
        :activeIndex="activeIndex"
        :activeModel.sync="activeModel"
        :topBarItems="topBarItems"
        @setIndex="setIndex"
    >
        <template #left-0>
            <left-menu
                :list="list"
                :system="system"
                @select="leftMenuSelect"
            ></left-menu>
        </template>
        <template #left-1>
            <b-card>
                <b-card-title>筛选</b-card-title>
                <label>设备名称</label>
                <b-form-input
                    placeholder="输入设备名称查询"
                    trim
                ></b-form-input>
            </b-card>
        </template>

        <template v-if="flag" #right-0>
            <b-tabs v-if="tab">
                <b-tab v-for="(v, i) in tab" :key="i" :title="v.title">
                    <div class="w-100 mb-2">
                        <div
                            v-for="(img, imgIndex) in v.img"
                            v-if="v.img"
                            class="mb-1 text-center"
                        >
                            <b-img
                                :key="imgIndex"
                                :src="img"
                                style="max-width: 100%; max-height: 100%"
                                fluid
                            ></b-img>
                        </div>
                    </div>

                    <b-card no-body>
                        <b-table
                            :fields="v.fields"
                            :items="v.items"
                            hover
                            responsive
                            striped
                            style="height: 32rem"
                        >
                            <template v-slot:cell(action)="data">
                                <b-form-checkbox
                                    name="check-button"
                                    switch
                                ></b-form-checkbox>
                            </template>
                        </b-table>
                    </b-card>
                </b-tab>
            </b-tabs>
        </template>

        <template #right-1>
            <b-card no-body>
                <b-table
                    :current-page="currentPage"
                    :fields="fields"
                    :items="items"
                    :per-page="perPage"
                    hover
                    responsive
                    striped
                >
                    <template v-slot:cell(params)="data">
                        <template
                            v-for="(param, param_index) in data.item.params"
                        >
                            <span
                                v-if="param.param_name"
                                :style="
                                    param.cur_value === null
                                        ? 'opacity:0.3'
                                        : ''
                                "
                            >
                                <span class="mx-50" v-if="param_index !== 0"
                                    >,</span
                                >
                                <span>{{
                                    param.param_name.replace(
                                        data.item.equip_name + "-",
                                        ""
                                    )
                                }}</span
                                >:
                                <span v-if="param.cur_value === null">N/A</span>
                                <span v-else>{{
                                    param.unit && param.unit.indexOf("||") > -1
                                        ? param.unit.split("||")[
                                              param.cur_value > 0 ? 1 : 0
                                          ]
                                        : param.cur_value +
                                          " " +
                                          (param.unit || "")
                                }}</span>
                            </span>
                        </template>
                    </template>
                    <template #cell(action)="data">
                        <div class="text-center">
                            <b-button
                                v-if="data.item.ip_address"
                                variant="warning"
                                size="sm"
                                >查看
                            </b-button>
                            <b-button
                                variant="success"
                                size="sm"
                                @click="equip_to_detail_model(data.item)"
                                :disabled="!data.item.detail_model_key"
                                >详情
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </b-card>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                align="right"
                first-number
                hide-goto-end-buttons
                last-number
                next-class="next-item"
                prev-class="prev-item"
            />
        </template>
        <template #right-2>
            <panel-group :show-title="false" group-uid="8b1202f2"></panel-group>
        </template>
    </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { reactive, toRefs, watch } from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";

export default {
    name: "index",
    components: {
        Layout,
        LeftMenu,
        panelGroup: () => import("@/views/panel-group"),
    },
    setup() {
        const showData = reactive({
            flag: false,
            tab: [],
        });

        // 框架相关参数
        const layoutParams = reactive({
            activeIndex: 0,
            activeModel: "",
            topBarItems: [
                {
                    title: "主页",
                    model: {
                        path: "Detail/transformer-substations/transformer-substations",
                        // dbIds: ["equipment_"],
                        rotate: "-8341,-22077,8567",
                    },
                },
                { title: "设备列表" },
                { title: "数据分析" },
            ],
        });
        //左侧菜单相关参数
        const leftMenuParams = reactive({
            list: [
                /* {
            title: "高压系统主接线",
            tab: [
                {
                    title: "高压配电系统",
                    fields: [
                        {
                            key: "wiring",
                            label: "接线",
                        },
                        {
                            key: "action",
                            label: "是否闭合",
                        },
                        {
                            key: "remarks",
                            label: "名称",
                        },
                        {
                            key: "Ia",
                            label: "A相电流 (A)",
                        },
                        {
                            key: "Ib",
                            label: "B相电流 (A)",
                        },
                        {
                            key: "Ic",
                            label: "C相电流 (A)",
                        },
                    ],
                    items: [
                        {
                            wiring: "AH1(201-2)",
                            remarks: "进线隔离柜",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH2(201)",
                            remarks: "1#主进线",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH3(44)",
                            remarks: "计量柜",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH4(211)",
                            remarks: "1#变",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH5(212)",
                            remarks: "3#变",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH6(245)",
                            remarks: "联络柜",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH7(245-5)",
                            remarks: "母线隔离",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH8(223)",
                            remarks: "4#变",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH9(222)",
                            remarks: "2#变",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH10(55)",
                            remarks: "计量柜",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH11(202)",
                            remarks: "2#主进线",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                        {
                            wiring: "AH12(202-2)",
                            remarks: "进线隔离柜",
                            Ia: 0.0,
                            Ib: 0.0,
                            Ic: 0.0,
                        },
                    ],
                    perPage: 10,
                    currentPage: 1,
                    img: [
                        "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E9%AB%98%E5%8E%8B.png",
                    ],
                },
            ],
        }, */
                {
                    title: "低压系统主接线",
                    tab: [
                        {
                            title: "1#变低压系统主接线",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_1%23%E5%8F%98%E4%BD%8E%E5%8E%8B_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_1%23%E5%8F%98%E4%BD%8E%E5%8E%8B_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA101",
                                    id: "D401",
                                    remarks: "1#变压器受电",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA102",
                                    id: "D410",
                                    remarks: "电容补偿主柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA103",
                                    id: "D410-1",
                                    remarks: "电容补偿主柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA104",
                                    id: "D411",
                                    remarks: "B3冷冻机2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA105",
                                    id: "D412",
                                    remarks: "冷却塔等",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA106",
                                    id: "D413",
                                    remarks: "南侧奇数层母线",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA107",
                                    id: "D414",
                                    remarks: "中间偶数层母线",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA108-1",
                                    id: "D415-1",
                                    remarks: "B3冷冻机3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA108-2",
                                    id: "D415-2",
                                    remarks: "B1车库出口电容雪",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA108-3",
                                    id: "D415-3",
                                    remarks: "B4人防动力2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA108-4",
                                    id: "D415-4",
                                    remarks: "B3-B1污水泵",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA108-5",
                                    id: "D415-5",
                                    remarks: "有源滤波",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-1",
                                    id: "D416-1",
                                    remarks: "B1车库入口热风幕",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-2",
                                    id: "D416-2",
                                    remarks: "人防照明1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-3",
                                    id: "D416-3",
                                    remarks: "中餐厅",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-4",
                                    id: "D416-4",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-5",
                                    id: "D416-5",
                                    remarks: "B1厨房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-6",
                                    id: "D416-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-7",
                                    id: "D416-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA109-8",
                                    id: "D416-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA110",
                                    id: "D445",
                                    remarks: "母线联络",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-1",
                                    id: "D426-1",
                                    remarks: "屋顶北侧风机2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-2",
                                    id: "D426-2",
                                    remarks: "屋顶南侧风机2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-3",
                                    id: "D426-3",
                                    remarks: "南侧楼1-12层空调",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-4",
                                    id: "D426-4",
                                    remarks: "B1-106",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-5",
                                    id: "D426-5",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "2#变低压系统主接线",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_2%23%E5%8F%98%E4%BD%8E%E5%8E%8B_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_2%23%E5%8F%98%E4%BD%8E%E5%8E%8B_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA111-6",
                                    id: "D426-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-7",
                                    id: "D426-7",
                                    remarks: "多功能厅",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA111-8",
                                    id: "D426-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA112",
                                    id: "D425",
                                    remarks: "北侧奇数层母线",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA113",
                                    id: "D424",
                                    remarks: "B3冷冻机1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-1",
                                    id: "D423-1",
                                    remarks: "厨房动力",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-2",
                                    id: "D423-2",
                                    remarks: "B4人防动力1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-3",
                                    id: "D423-3",
                                    remarks: "北楼顶风机热回收",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-4",
                                    id: "D423-4",
                                    remarks: "南楼顶风机热回收",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-5",
                                    id: "D423-5",
                                    remarks: "人防照明2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-6",
                                    id: "D423-6",
                                    remarks: "12层厨房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-7",
                                    id: "D423-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA114-8",
                                    id: "D423-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-1",
                                    id: "D422-1",
                                    remarks: "厨房动力2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-2",
                                    id: "D422-2",
                                    remarks: "B1-B3普通动力",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-3",
                                    id: "D422-3",
                                    remarks: "北楼1-12层风机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-4",
                                    id: "D422-4",
                                    remarks: "B1-B3污水泵",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-5",
                                    id: "D422-5",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-6",
                                    id: "D422-6",
                                    remarks: "B2充电桩",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-7",
                                    id: "D422-7",
                                    remarks: "有源滤波",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA115-8",
                                    id: "D422-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA116",
                                    id: "D421",
                                    remarks: "B3冷冻配套泵",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA117",
                                    id: "D420-1",
                                    remarks: "电容补偿辅柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA118",
                                    id: "D420",
                                    remarks: "电容补偿主柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA119",
                                    id: "D402",
                                    remarks: "2#变压器受电",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "3#变低压系统主接线",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_3%23%E5%8F%98%E4%BD%8E%E5%8E%8B_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_3%23%E5%8F%98%E4%BD%8E%E5%8E%8B_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "C-AA8",
                                    id: "D406",
                                    remarks: "互头电源柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA9-1",
                                    id: "D461-1",
                                    remarks: "南侧消防电梯1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA9-2",
                                    id: "D461-2",
                                    remarks: "北侧消防电梯1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA9-3",
                                    id: "D461-3",
                                    remarks: "南侧防火卷帘",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA9-4",
                                    id: "D461-4",
                                    remarks: "北侧防火卷帘",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA9-5",
                                    id: "D461-5",
                                    remarks: "应急照明",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA9-6",
                                    id: "D461-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-1",
                                    id: "D462-1",
                                    remarks: "南侧客梯1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-2",
                                    id: "D462-2",
                                    remarks: "南侧客梯2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-3",
                                    id: "D462-3",
                                    remarks: "北侧客梯1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-4",
                                    id: "D462-4",
                                    remarks: "北侧客梯2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-5",
                                    id: "D462-5",
                                    remarks: "中水给水泵",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-6",
                                    id: "D462-6",
                                    remarks: "北侧首层银行",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-7",
                                    id: "D462-7",
                                    remarks: "南侧首层银行",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA10-8",
                                    id: "D462-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA11-1",
                                    id: "D463-1",
                                    remarks: "南侧消防电梯2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA11-2",
                                    id: "D463-2",
                                    remarks: "北侧消防电梯2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA11-3",
                                    id: "D463-3",
                                    remarks: "B4平时兼消防动力",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA11-4",
                                    id: "D463-4",
                                    remarks: "北侧屋顶消防风机1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA11-5",
                                    id: "D463-5",
                                    remarks: "北侧屋顶消防风机2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA11-6",
                                    id: "D463-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "3#变低压系统主接线1",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_3%23%E5%8F%98%E4%BD%8E%E5%8E%8B1_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_3%23%E5%8F%98%E4%BD%8E%E5%8E%8B1_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "接线",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA201",
                                    id: "D403",
                                    remarks: "3#变压器受电",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA202",
                                    id: "D430",
                                    remarks: "电容补偿主柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA203",
                                    id: "D430-1",
                                    remarks: "电容补偿主柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-1",
                                    id: "D431-1",
                                    remarks: "消防泵房备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-2",
                                    id: "D431-2",
                                    remarks: "南侧屋顶消防风机1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-3",
                                    id: "D431-3",
                                    remarks: "南侧屋顶消防风机2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-4",
                                    id: "D431-4",
                                    remarks: "北侧屋顶消防风机1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-5",
                                    id: "D431-5",
                                    remarks: "北侧屋顶消防风机2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-6",
                                    id: "D431-6",
                                    remarks: "有源滤波",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-7",
                                    id: "D431-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA204-8",
                                    id: "D431-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-1",
                                    id: "D432-1",
                                    remarks: "南侧消防火卷帘主用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-2",
                                    id: "D432-2",
                                    remarks: "北侧消防火卷帘主用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-3",
                                    id: "D432-3",
                                    remarks: "B3平时兼消防动力",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-4",
                                    id: "D432-4",
                                    remarks: "B2消防风机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-5",
                                    id: "D432-5",
                                    remarks: "B4平时兼消防动力",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-6",
                                    id: "D432-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-7",
                                    id: "D432-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA205-8",
                                    id: "D432-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-1",
                                    id: "D433-1",
                                    remarks: "南侧消防电梯1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-2",
                                    id: "D433-2",
                                    remarks: "北侧消防电梯1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-3",
                                    id: "D433-3",
                                    remarks: "北侧消防电梯2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-4",
                                    id: "D433-4",
                                    remarks: "南侧消防电梯2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-5",
                                    id: "D433-5",
                                    remarks: "应急照明",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-6",
                                    id: "D433-6",
                                    remarks: "消防控制室",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-7",
                                    id: "D433-7",
                                    remarks: "B1消防风机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA206-8",
                                    id: "D433-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-1",
                                    id: "D434-1",
                                    remarks: "变电室发电机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-2",
                                    id: "D434-2",
                                    remarks: "弱电机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "3#变低压系统主接线2",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_3%23%E5%8F%98%E4%BD%8E%E5%8E%8B2_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_3%23%E5%8F%98%E4%BD%8E%E5%8E%8B2_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "接线",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA207-3",
                                    id: "D434-3",
                                    remarks: "有线电视",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-4",
                                    id: "D434-4",
                                    remarks: "弱电竖井电源",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-5",
                                    id: "D434-5",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-6",
                                    id: "D434-6",
                                    remarks: "走道一级负荷",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-7",
                                    id: "D434-7",
                                    remarks: "国管UPS备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA207-8",
                                    id: "D434-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-1",
                                    id: "D435-1",
                                    remarks: "屋顶卫星机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-2",
                                    id: "D435-2",
                                    remarks: "安防控制室",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-3",
                                    id: "D435-3",
                                    remarks: "北侧首层银行",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-4",
                                    id: "D435-4",
                                    remarks: "南侧首层银行",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-5",
                                    id: "D435-5",
                                    remarks: "地下照明3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-6",
                                    id: "D435-6",
                                    remarks: "地下照明4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-7",
                                    id: "D435-7",
                                    remarks: "B1-105, 106, 108",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA208-8",
                                    id: "D435-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-1",
                                    id: "D436-1",
                                    remarks: "南侧客厅1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-2",
                                    id: "D436-2",
                                    remarks: "南侧客厅2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-3",
                                    id: "D436-3",
                                    remarks: "南侧停车场客梯备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-4",
                                    id: "D436-4",
                                    remarks: "中水-给水泵",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-5",
                                    id: "D436-5",
                                    remarks: "屋顶外沿电溶雪",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-6",
                                    id: "D436-6",
                                    remarks: "直流屏",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-7",
                                    id: "D436-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA209-8",
                                    id: "D436-8",
                                    remarks: "12层弱电机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-1",
                                    id: "D437-1",
                                    remarks: "北侧客梯1备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-2",
                                    id: "D437-2",
                                    remarks: "北侧客梯2备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-3",
                                    id: "D437-3",
                                    remarks: "北侧停车场客梯主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-4",
                                    id: "D437-4",
                                    remarks: "扶梯主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-5",
                                    id: "D437-5",
                                    remarks: "北侧屋顶擦窗机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-6",
                                    id: "D437-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "4#变低压系统主接线1",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_4%23%E5%8F%98%E4%BD%8E%E5%8E%8B_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_4%23%E5%8F%98%E4%BD%8E%E5%8E%8B_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA210-7",
                                    id: "D437-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA210-8",
                                    id: "D437-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA211",
                                    id: "D438",
                                    remarks: "南侧偶数层母线",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-1",
                                    id: "D439-1",
                                    remarks: "热力机房主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-2",
                                    id: "D439-2",
                                    remarks: "信息机房预留",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-3",
                                    id: "D439-3",
                                    remarks: "信息机房预留2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-4",
                                    id: "D439-4",
                                    remarks: "信息机房预留3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-5",
                                    id: "D439-5",
                                    remarks: "会议系统主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-6",
                                    id: "D439-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-7",
                                    id: "D439-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA212-8",
                                    id: "D439-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA213",
                                    id: "D467",
                                    remarks: "联络",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-1",
                                    id: "D448-1",
                                    remarks: "南侧停车场客梯主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-2",
                                    id: "D448-2",
                                    remarks: "员工餐厅",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-3",
                                    id: "D448-3",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-4",
                                    id: "D448-4",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-5",
                                    id: "D448-5",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-6",
                                    id: "D448-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-7",
                                    id: "D448-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA214-8",
                                    id: "D448-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA215",
                                    id: "D447",
                                    remarks: "中间奇数层母线",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA216",
                                    id: "D446",
                                    remarks: "北侧偶数层母线",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-1",
                                    id: "D445-1",
                                    remarks: "北侧停车场客梯备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-2",
                                    id: "D445-2",
                                    remarks: "扶梯备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-3",
                                    id: "D445-3",
                                    remarks: "热力机房备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-4",
                                    id: "D445-4",
                                    remarks: "南侧屋顶擦窗机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-5",
                                    id: "D445-5",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-6",
                                    id: "D445-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "4#变低压系统主接线2",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_4%23%E5%8F%98%E4%BD%8E%E5%8E%8B2_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_4%23%E5%8F%98%E4%BD%8E%E5%8E%8B2_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA217-7",
                                    id: "D445-7",
                                    remarks: "AD-WD-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA217-8",
                                    id: "D445-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-1",
                                    id: "D444-1",
                                    remarks: "地下照明1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-2",
                                    id: "D444-2",
                                    remarks: "地下照明2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-3",
                                    id: "D444-3",
                                    remarks: "邮局",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-4",
                                    id: "D444-4",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-5",
                                    id: "D444-5",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-6",
                                    id: "D444-6",
                                    remarks: "AP-B1-Z1新风机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-7",
                                    id: "D444-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA218-8",
                                    id: "D444-8",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-1",
                                    id: "D443-1",
                                    remarks: "南侧夜景照明",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-2",
                                    id: "D443-2",
                                    remarks: "北侧夜景照明",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-3",
                                    id: "D443-3",
                                    remarks: "南侧首层商业",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-4",
                                    id: "D443-4",
                                    remarks: "北侧首层咖啡厅",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-5",
                                    id: "D443-5",
                                    remarks: "直流屏备",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-6",
                                    id: "D443-6",
                                    remarks: "有源滤波",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-7",
                                    id: "D443-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA219-8",
                                    id: "D443-8",
                                    remarks: "景观园林照明",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA220",
                                    id: "D442",
                                    remarks: "至C-AA4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA221",
                                    id: "D441",
                                    remarks: "至C-AA8",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA222",
                                    id: "D440-1",
                                    remarks: "电容补偿辅柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA223",
                                    id: "D440",
                                    remarks: "电容补偿主柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "AA224",
                                    id: "D404",
                                    remarks: "4#变压器受电",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "应急低压系统主接线1",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E5%BA%94%E6%80%A5%E4%BD%8E%E5%8E%8B1_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E5%BA%94%E6%80%A5%E4%BD%8E%E5%8E%8B1_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "C-AA4",
                                    id: "D405",
                                    remarks: "互头电源柜",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-1",
                                    id: "D451-1",
                                    remarks: "消防泵房主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-2",
                                    id: "D451-2",
                                    remarks: "南侧屋顶消防风机主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-3",
                                    id: "D451-3",
                                    remarks: "南侧屋顶消防风机主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-4",
                                    id: "D451-4",
                                    remarks: "配电室发电机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-5",
                                    id: "D451-5",
                                    remarks: "B2消防风机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-6",
                                    id: "D451-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-7",
                                    id: "D451-7",
                                    remarks: "消防控制室",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA5-8",
                                    id: "D451-8",
                                    remarks: "B3消防动力",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-1",
                                    id: "D452-1",
                                    remarks: "屋顶卫星机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-2",
                                    id: "D452-2",
                                    remarks: "弱电机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-3",
                                    id: "D452-3",
                                    remarks: "有线电视",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-4",
                                    id: "D452-4",
                                    remarks: "弱电竖井电源",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-5",
                                    id: "D452-5",
                                    remarks: "安防监控室",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-6",
                                    id: "D452-6",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-7",
                                    id: "D452-7",
                                    remarks: "消防风机",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA6-8",
                                    id: "D452-8",
                                    remarks: "走道一级负荷",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-1",
                                    id: "D453-1",
                                    remarks: "信息机房预留1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-2",
                                    id: "D453-2",
                                    remarks: "信息机房预留2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-3",
                                    id: "D453-3",
                                    remarks: "信息机房预留3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-4",
                                    id: "D453-4",
                                    remarks: "会议系统预留3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-5",
                                    id: "D453-5",
                                    remarks: "多功能厅",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-6",
                                    id: "D453-6",
                                    remarks: "国管UPS主",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-7",
                                    id: "D453-7",
                                    remarks: "备用",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    wiring: "C-AA7-8",
                                    id: "D453-8",
                                    remarks: "12层弱电机房",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                    ],
                },
                {
                    title: "竖井系统主接线",
                    tab: [
                        {
                            title: "低压竖井系统主接线1",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%951_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%951_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                                {
                                    key: "active_electricity",
                                    label: "有功电度",
                                },
                                /* {
                                    key: "reactive_electricity",
                                    label: "无功电度",
                                }, */
                            ],
                            items: [
                                {
                                    wiring: "1AL-2-1",
                                    id: "D1AL-2-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-2-2",
                                    id: "D1AL-2-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-2-3",
                                    id: "D1AL-2-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-2-4",
                                    id: "D1AL-2-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-2-5",
                                    id: "D1AL-2-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-2-6",
                                    id: "D1AL-2-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-3-1",
                                    id: "D1AL-3-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-3-2",
                                    id: "D1AL-3-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-3-3",
                                    id: "D1AL-3-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-3-4",
                                    id: "D1AL-3-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-3-5",
                                    id: "D1AL-3-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-3-6",
                                    id: "D1AL-3-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-4-1",
                                    id: "D1AL-4-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-4-2",
                                    id: "D1AL-4-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-4-3",
                                    id: "D1AL-4-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-4-4",
                                    id: "D1AL-4-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-4-5",
                                    id: "D1AL-4-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-5-1",
                                    id: "D1AL-5-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-5-2",
                                    id: "D1AL-5-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-5-3",
                                    id: "D1AL-5-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-5-4",
                                    id: "D1AL-5-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-5-5",
                                    id: "D1AL-5-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-6-1",
                                    id: "D1AL-6-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-6-2",
                                    id: "D1AL-6-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-6-3",
                                    id: "D1AL-6-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-6-4",
                                    id: "D1AL-6-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-6-5",
                                    id: "D1AL-6-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-6-6",
                                    id: "D1AL-6-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-7-1",
                                    id: "D1AL-7-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-7-2",
                                    id: "D1AL-7-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-7-3",
                                    id: "D1AL-7-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-7-4",
                                    id: "D1AL-7-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-7-5",
                                    id: "D1AL-7-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-7-6",
                                    id: "D1AL-7-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "低压竖井系统主接线2",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%952_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%952_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                                {
                                    key: "active_electricity",
                                    label: "有功电度",
                                },
                                /* {
                                    key: "reactive_electricity",
                                    label: "无功电度",
                                }, */
                            ],
                            items: [
                                {
                                    wiring: "1AL-8-1",
                                    id: "D1AL-8-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-8-2",
                                    id: "D1AL-8-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-8-3",
                                    id: "D1AL-8-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-8-4",
                                    id: "D1AL-8-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-8-5",
                                    id: "D1AL-8-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-8-6",
                                    id: "D1AL-8-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-9-1",
                                    id: "D1AL-9-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-9-2",
                                    id: "D1AL-9-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-9-3",
                                    id: "D1AL-9-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-9-4",
                                    id: "D1AL-9-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-9-5",
                                    id: "D1AL-9-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-9-6",
                                    id: "D1AL-9-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-10-1",
                                    id: "D1AL-10-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-10-2",
                                    id: "D1AL-10-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-10-3",
                                    id: "D1AL-10-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-10-4",
                                    id: "D1AL-10-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-10-5",
                                    id: "D1AL-10-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "1AL-10-6",
                                    id: "D1AL-10-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-2-1",
                                    id: "D2AL-2-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-2-2",
                                    id: "D2AL-2-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-2-3",
                                    id: "D2AL-2-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-2-4",
                                    id: "D2AL-2-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-2-5",
                                    id: "D2AL-2-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-2-6",
                                    id: "D2AL-2-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-3-1",
                                    id: "D2AL-3-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-3-2",
                                    id: "D2AL-3-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-3-3",
                                    id: "D2AL-3-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-3-4",
                                    id: "D2AL-3-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-3-5",
                                    id: "D2AL-3-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-3-6",
                                    id: "D2AL-3-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-4-1",
                                    id: "D2AL-4-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-4-2",
                                    id: "D2AL-4-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-4-3",
                                    id: "D2AL-4-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-4-4",
                                    id: "D2AL-4-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-4-5",
                                    id: "D2AL-4-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "低压竖井系统主接线3",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%953_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%953_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                                {
                                    key: "active_electricity",
                                    label: "有功电度",
                                },
                                /* {
                                    key: "reactive_electricity",
                                    label: "无功电度",
                                }, */
                            ],
                            items: [
                                {
                                    wiring: "2AL-5-1",
                                    id: "D2AL-5-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-5-2",
                                    id: "D2AL-5-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-5-3",
                                    id: "D2AL-5-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-5-4",
                                    id: "D2AL-5-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-5-5",
                                    id: "D2AL-5-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-5-6",
                                    id: "D2AL-5-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-6-1",
                                    id: "D2AL-6-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-6-2",
                                    id: "D2AL-6-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-6-3",
                                    id: "D2AL-6-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-6-4",
                                    id: "D2AL-6-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-6-5",
                                    id: "D2AL-6-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-6-6",
                                    id: "D2AL-6-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-7-1",
                                    id: "D2AL-7-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-7-2",
                                    id: "D2AL-7-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-7-3",
                                    id: "D2AL-7-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-7-4",
                                    id: "D2AL-7-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-7-5",
                                    id: "D2AL-7-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-7-6",
                                    id: "D2AL-7-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-8-1",
                                    id: "D2AL-8-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-8-2",
                                    id: "D2AL-8-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-8-3",
                                    id: "D2AL-8-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-8-4",
                                    id: "D2AL-8-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-8-5",
                                    id: "D2AL-8-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-8-6",
                                    id: "D2AL-8-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-9-1",
                                    id: "D2AL-9-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-9-2",
                                    id: "D2AL-9-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-9-3",
                                    id: "D2AL-9-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-9-4",
                                    id: "D2AL-9-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-9-5",
                                    id: "D2AL-9-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-9-6",
                                    id: "D2AL-9-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-10-1",
                                    id: "D2AL-10-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-10-2",
                                    id: "D2AL-10-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-10-3",
                                    id: "D2AL-10-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-10-4",
                                    id: "D2AL-10-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-10-5",
                                    id: "D2AL-10-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "2AL-10-6",
                                    id: "D2AL-10-6",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "低压竖井系统主接线4",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%954_1.png",
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%954_2.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                                {
                                    key: "active_electricity",
                                    label: "有功电度",
                                },
                                /* {
                                    key: "reactive_electricity",
                                    label: "无功电度",
                                }, */
                            ],
                            items: [
                                {
                                    wiring: "3AL-4-1",
                                    id: "D3AL-4-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-4-2",
                                    id: "D3AL-4-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-4-3",
                                    id: "D3AL-4-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-4-4",
                                    id: "D3AL-4-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-4-5",
                                    id: "D3AL-4-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-5-1",
                                    id: "D3AL-5-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-5-2",
                                    id: "D3AL-5-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-5-3",
                                    id: "D3AL-5-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-5-4",
                                    id: "D3AL-5-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-5-5",
                                    id: "D3AL-5-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-6-1",
                                    id: "D3AL-6-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-6-2",
                                    id: "D3AL-6-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-6-3",
                                    id: "D3AL-6-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-6-4",
                                    id: "D3AL-6-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-6-5",
                                    id: "D3AL-6-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-7-1",
                                    id: "D3AL-7-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-7-2",
                                    id: "D3AL-7-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-7-3",
                                    id: "D3AL-7-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-7-4",
                                    id: "D3AL-7-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-7-5",
                                    id: "D3AL-7-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-8-1",
                                    id: "D3AL-8-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-8-2",
                                    id: "D3AL-8-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-8-3",
                                    id: "D3AL-8-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-8-4",
                                    id: "D3AL-8-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-8-5",
                                    id: "D3AL-8-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-9-1",
                                    id: "D3AL-9-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-9-2",
                                    id: "D3AL-9-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-9-3",
                                    id: "D3AL-9-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-9-4",
                                    id: "D3AL-9-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-9-5",
                                    id: "D3AL-9-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "低压竖井系统主接线5",
                            img: [
                                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E5%8F%98%E9%85%8D%E7%94%B5%E7%B3%BB%E7%BB%9F%E7%94%B5%E8%B7%AF%E5%9B%BE/0_%E4%BD%8E%E5%8E%8B%E7%AB%96%E4%BA%955.png",
                            ],
                            fields: [
                                {
                                    key: "wiring",
                                    label: "线路",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "action",
                                    label: "闭合状态",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                                {
                                    key: "active_electricity",
                                    label: "有功电度",
                                },
                                /* {
                                    key: "reactive_electricity",
                                    label: "无功电度",
                                }, */
                            ],
                            items: [
                                {
                                    wiring: "3AL-10-1",
                                    id: "D3AL-10-1",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-10-2",
                                    id: "D3AL-10-2",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-10-3",
                                    id: "D3AL-10-3",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-10-4",
                                    id: "D3AL-10-4",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                                {
                                    wiring: "3AL-10-5",
                                    id: "D3AL-10-5",
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                    active_electricity: 0.0,
                                    reactive_electricity: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                    ],
                },
                /* {
                    title: "直流屏系统主接线",
                    img: [],
                    tab: [
                        {
                            title: "直流屏测量参数",
                            fields: [
                                {
                                    key: "bus_voltage",
                                    label: "母线电压(V)",
                                },
                                {
                                    key: "charge_current",
                                    label: "充电电流 (A)",
                                },
                                {
                                    key: "charge_voltage",
                                    label: "充电电压(V)",
                                },
                                {
                                    key: "bpt_voltage",
                                    label: "电池组端电压(V)",
                                },
                                {
                                    key: "spare",
                                    label: "备用",
                                },
                                {
                                    key: "et",
                                    label: "环境温度",
                                },
                                {
                                    key: "action",
                                    label: "是否闭合",
                                },
                            ],
                            items: [
                                {
                                    bus_voltage: 0.0,
                                    charge_current: 0.0,
                                    charge_voltage: 0.0,
                                    bpt_voltage: 0.0,
                                    spare: 0.0,
                                    et: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                    ],
                }, */
                {
                    title: "低压进线母联测量",
                    tab: [
                        {
                            title: "参数1",
                            fields: [
                                {
                                    key: "wiring",
                                    label: "路线",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "pv_v1",
                                    label: "相电压 V1 (V)",
                                },
                                {
                                    key: "pv_v2",
                                    label: "相电压 V2 (V)",
                                },
                                {
                                    key: "pv_v3",
                                    label: "相电压 V3 (V)",
                                },
                                {
                                    key: "lv_v12",
                                    label: "线电压 V12 (V)",
                                },
                                {
                                    key: "lv_v23",
                                    label: "线电压 V23 (V)",
                                },
                                {
                                    key: "lv_v31",
                                    label: "线电压 V31 (V)",
                                },
                                {
                                    key: "plc_I1",
                                    label: "相（线）电流 I1 (A)",
                                },
                                {
                                    key: "plc_I2",
                                    label: "相（线）电流 I2 (A)",
                                },
                                {
                                    key: "plc_I3",
                                    label: "相（线）电流 I3 (A)",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA101",
                                    id: "D401",
                                    remarks: "1#进线",
                                    pv_v1: 0.0,
                                    pv_v2: 0.0,
                                    pv_v3: 0.0,
                                    lv_v12: 0.0,
                                    lv_v23: 0.0,
                                    lv_v31: 0.0,
                                    plc_I1: 0.0,
                                    plc_I2: 0.0,
                                    plc_I3: 0.0,
                                },
                                {
                                    wiring: "AA110",
                                    id: "D445",
                                    remarks: "联络",
                                    pv_v1: 0.0,
                                    pv_v2: 0.0,
                                    pv_v3: 0.0,
                                    lv_v12: 0.0,
                                    lv_v23: 0.0,
                                    lv_v31: 0.0,
                                    plc_I1: 0.0,
                                    plc_I2: 0.0,
                                    plc_I3: 0.0,
                                },
                                {
                                    wiring: "AA119",
                                    id: "D402",
                                    remarks: "2#进线",
                                    pv_v1: 0.0,
                                    pv_v2: 0.0,
                                    pv_v3: 0.0,
                                    lv_v12: 0.0,
                                    lv_v23: 0.0,
                                    lv_v31: 0.0,
                                    plc_I1: 0.0,
                                    plc_I2: 0.0,
                                    plc_I3: 0.0,
                                },
                                {
                                    wiring: "AA201",
                                    id: "D403",
                                    remarks: "3#进线",
                                    pv_v1: 0.0,
                                    pv_v2: 0.0,
                                    pv_v3: 0.0,
                                    lv_v12: 0.0,
                                    lv_v23: 0.0,
                                    lv_v31: 0.0,
                                    plc_I1: 0.0,
                                    plc_I2: 0.0,
                                    plc_I3: 0.0,
                                },
                                {
                                    wiring: "AA213",
                                    id: "D467",
                                    remarks: "联络",
                                    pv_v1: 0.0,
                                    pv_v2: 0.0,
                                    pv_v3: 0.0,
                                    lv_v12: 0.0,
                                    lv_v23: 0.0,
                                    lv_v31: 0.0,
                                    plc_I1: 0.0,
                                    plc_I2: 0.0,
                                    plc_I3: 0.0,
                                },
                                {
                                    wiring: "AA224",
                                    id: "D404",
                                    remarks: "4#进线",
                                    pv_v1: 0.0,
                                    pv_v2: 0.0,
                                    pv_v3: 0.0,
                                    lv_v12: 0.0,
                                    lv_v23: 0.0,
                                    lv_v31: 0.0,
                                    plc_I1: 0.0,
                                    plc_I2: 0.0,
                                    plc_I3: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "参数2",
                            fields: [
                                {
                                    key: "wiring",
                                    label: "路线",
                                },
                                {
                                    key: "id",
                                    label: "编号",
                                },
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "frequency",
                                    label: "频率 F (Hz)",
                                },
                                {
                                    key: "sap",
                                    label: "系统有功功率 Psum",
                                },
                                {
                                    key: "srp",
                                    label: "系统无功功率 Qsum",
                                },
                                {
                                    key: "spf",
                                    label: "系统功率因素 PF",
                                },
                                {
                                    key: "tae",
                                    label: "总有功电度 Ep_total",
                                },
                                {
                                    key: "tre",
                                    label: "总无功电度 Eq_total",
                                },
                            ],
                            items: [
                                {
                                    wiring: "AA101",
                                    id: "D401",
                                    remarks: "1#进线",
                                    frequency: 0.0,
                                    sap: 0.0,
                                    srp: 0.0,
                                    spf: 0.0,
                                    tae: 0.0,
                                    tre: 0.0,
                                },
                                {
                                    wiring: "AA110",
                                    id: "D445",
                                    remarks: "联络",
                                    frequency: 0.0,
                                    sap: 0.0,
                                    srp: 0.0,
                                    spf: 0.0,
                                    tae: 0.0,
                                    tre: 0.0,
                                },
                                {
                                    wiring: "AA119",
                                    id: "D402",
                                    remarks: "2#进线",
                                    frequency: 0.0,
                                    sap: 0.0,
                                    srp: 0.0,
                                    spf: 0.0,
                                    tae: 0.0,
                                    tre: 0.0,
                                },
                                {
                                    wiring: "AA201",
                                    id: "D403",
                                    remarks: "3#进线",
                                    frequency: 0.0,
                                    sap: 0.0,
                                    srp: 0.0,
                                    spf: 0.0,
                                    tae: 0.0,
                                    tre: 0.0,
                                },
                                {
                                    wiring: "AA213",
                                    id: "D467",
                                    remarks: "联络",
                                    frequency: 0.0,
                                    sap: 0.0,
                                    srp: 0.0,
                                    spf: 0.0,
                                    tae: 0.0,
                                    tre: 0.0,
                                },
                                {
                                    wiring: "AA224",
                                    id: "D404",
                                    remarks: "4#进线",
                                    frequency: 0.0,
                                    sap: 0.0,
                                    srp: 0.0,
                                    spf: 0.0,
                                    tae: 0.0,
                                    tre: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                    ],
                },
                {
                    title: "变压器温度",
                    tab: [
                        {
                            title: "变压器温度测量参数",
                            fields: [
                                {
                                    key: "remarks",
                                    label: "名称",
                                },
                                {
                                    key: "pat",
                                    label: "A相温度",
                                },
                                {
                                    key: "pbt",
                                    label: "B相温度",
                                },
                                {
                                    key: "pct",
                                    label: "C相温度",
                                },
                            ],
                            items: [
                                {
                                    remarks: "1#变压器",
                                    pat: 0.0,
                                    pbt: 0.0,
                                    pct: 0.0,
                                },
                                {
                                    remarks: "2#变压器",
                                    pat: 0.0,
                                    pbt: 0.0,
                                    pct: 0.0,
                                },
                                {
                                    remarks: "3#变压器",
                                    pat: 0.0,
                                    pbt: 0.0,
                                    pct: 0.0,
                                },
                                {
                                    remarks: "4#变压器",
                                    pat: 0.0,
                                    pbt: 0.0,
                                    pct: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                    ],
                },
                {
                    title: "发电机",
                    tab: [
                        {
                            title: "发电机参数1",
                            fields: [
                                {
                                    key: "engine",
                                    label: "发电机名称"
                                },
                                {
                                    key: "state",
                                    label: "运行状态",
                                },
                                {
                                    key: "percentKw",
                                    label: "千瓦百分比 (%)",
                                },
                                {
                                    key: "nfpa",
                                    label: "报警",
                                },
                                {
                                    key: "frequency",
                                    label: "频率 (Hz)",
                                },
                                
                                {
                                    key: "Vbattery",
                                    label: "电池电压 (V)",
                                },
                                {
                                    key: "Poil",
                                    label: "油压 (kPa)",
                                },
                                {
                                    key: "Toil",
                                    label: "油温 (K)",
                                },
                                {
                                    key: "Tcoolant",
                                    label: "冷却液温度 (K)",
                                },
                                {
                                    key: "rpm",
                                    label: "发电机转速 (rpm)",
                                },
                            ],
                            items: [
                                {
                                    engine: "1#发电机",
                                    state: 0.0,
                                    percentKw: 0.0,
                                    nfpa: 0.0,
                                    frequency: 0.0,
                                    Vbattery: 0.0,
                                    Poil: 0.0,
                                    Toil: 0.0,
                                    Tcoolant: 0.0,
                                    rpm: 0.0,
                                },
                                {
                                    engine: "2#发电机",
                                    state: 0.0,
                                    percentKw: 0.0,
                                    nfpa: 0.0,
                                    frequency: 0.0,
                                    Vbattery: 0.0,
                                    Poil: 0.0,
                                    Toil: 0.0,
                                    Tcoolant: 0.0,
                                    rpm: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                        {
                            title: "发电机参数2",
                            fields: [
                                {
                                    key: "engine",
                                    label: "发电机名称"
                                },
                                {
                                    key: "totalKva",
                                    label: "总功率 (kVA)",
                                },
                                {
                                    key: "totalKw",
                                    label: "有功功率 (kW)",
                                },
                                {
                                    key: "totalKvar",
                                    label: "无功功率 (kVAR)",
                                },
                                {
                                    key: "Vab",
                                    label: "AB线电压 (V)",
                                },
                                {
                                    key: "Vbc",
                                    label: "BC线电压 (V)",
                                },
                                {
                                    key: "Vca",
                                    label: "CA线电压 (V)",
                                },
                                {
                                    key: "Va",
                                    label: "A相电压 (V)",
                                },
                                {
                                    key: "Vb",
                                    label: "B相电压 (V)",
                                },
                                {
                                    key: "Vc",
                                    label: "C相电压 (V)",
                                },
                                {
                                    key: "Ia",
                                    label: "A相电流 (A)",
                                },
                                {
                                    key: "Ib",
                                    label: "B相电流 (A)",
                                },
                                {
                                    key: "Ic",
                                    label: "C相电流 (A)",
                                },
                            ],
                            items: [
                                {
                                    engine: "1#发电机",
                                    totalKva: 0.0,
                                    totalKw: 0.0,
                                    totalKvar: 0.0,
                                    Vab: 0.0,
                                    Vbc: 0.0,
                                    Vca: 0.0,
                                    Va: 0.0,
                                    Vb: 0.0,
                                    Vc: 0.0,
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                                {
                                    engine: "2#发电机",
                                    totalKva: 0.0,
                                    totalKw: 0.0,
                                    totalKvar: 0.0,
                                    Vab: 0.0,
                                    Vbc: 0.0,
                                    Vca: 0.0,
                                    Va: 0.0,
                                    Vb: 0.0,
                                    Vc: 0.0,
                                    Ia: 0.0,
                                    Ib: 0.0,
                                    Ic: 0.0,
                                },
                            ],
                            perPage: 10,
                            currentPage: 1,
                        },
                    ],
                },
            ],
            system: false,
        });

        watch(
            () => store.state.jsf35.equip_list,
            (equip_list) => {
                let params_list = [];
                equip_list.map((equip) => {
                    const key = equip.equip_meter_code.split("_").pop();
                    let params_item = {};
                    equip.params.map((param) => {
                        if (param.param_code.indexOf("I") === 0) {
                            params_item[param.param_code] = param.cur_value;
                        }
                    });
                    params_list[key] = params_item;
                });

                leftMenuParams.list.map((listItem) => {
                    listItem.tab.map((tabItem) => {
                        tabItem.items.map((item) => {
                            if (item.id && params_list[item.id]) {
                                const { I1, I2, I3 } = params_list[item.id];
                                item.Ia = I1;
                                item.Ib = I2;
                                item.Ic = I3;
                            }
                        });
                    });
                });

                console.log("params_list", params_list);
            }
        );

        // 左侧菜单选中事件
        const leftMenuSelect = (event) => {
            console.log("leftMenuSelectEvent", event);
            // activeModel.value = event.model
            layoutParams.topBarItems[0].model = null;
            showData.tab = event.tab;
            showData.flag = true;
        };

        const setIndex = (e) => {
            showData.flag = false;
            layoutParams.topBarItems[0].model = {
                path: "Detail/transformer-substations/transformer-substations",
                dbIds: ["JSF-peidian"],
                rotate: "-8341,-22077,8567",
            };
            layoutParams.activeIndex = e;
        };

        const data = reactive({
            fields: [
                { label: "ID", key: "equip_id" },
                { label: "设备名称", key: "equip_name" },
                { label: "设备编号", key: "equip_meter_code" },
                { label: "楼层", key: "floor" },
                { label: "参数状态", key: "params" },
                { label: "操作", key: "action" },
            ],
            items: [],
            perPage: 20,
            currentPage: 1,
            rows: 1,
            select: 0,
        });

        watch(
            () => store.state.jsf35.equip_list,
            (list) => {
                data.items = list;
                data.rows = list.length;
            }
        );

        //设备列表跳转到模型大样图
        const equip_to_detail_model = (item) => {
            setIndex(0);
            setTimeout(() => {
                store.commit("jsf35/setEquipSelected", item);
                leftMenuSelect(item);
            }, 1000);
        };

        return {
            ...toRefs(layoutParams),
            ...toRefs(leftMenuParams),
            leftMenuSelect,
            setIndex,
            ...toRefs(showData),
            ...toRefs(data),
            equip_to_detail_model,
        };
    },
};
</script>

<style lang="scss" scoped></style>
